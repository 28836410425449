import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import axios, {get} from "axios";
import config from "../conf/config";
import {ManageSession, ManageSession as manageSession} from "../Service/ManageSession";
import logoTitreOlympiades from "../img/logo-titre-olympiades.png";
import carte from "../img/ico/carte.png";
import flecheIncurve from "../img/ico/Fleche-incurvé.png";
import help from "../img/ico/levez-la-main-pour-demander.png";
import CheckRole from "../Service/Securite";

function User() {

    const adressApi = config.apiUrl;

    const navigate = useNavigate();

    const [nomEquipe, setNomEquipe] = useState("Nom de l'équipe");
    const [firstActivity, setFirstActivity] = useState("Nom de l'activité");
    const [slogantEquipe, setSlogantEquipe] = useState("Créer votre slogant ce midi !");

    useEffect(() => {
        if (!CheckRole("1")){
            navigate("/redirect");
        }
        getInfoEquipe();
    },[]);

    const getInfoEquipe = () => {
        axios.get(`${adressApi}/equipes/${manageSession.getInfoUser().equipe}`, {headers: {Authorization: `Bearer ${ManageSession.getToken()}`}})
            .then(res => {
                console.log(res);
                setNomEquipe(res.data[0].name);
                setSlogantEquipe(res.data[0].slogant)
                getFirstActivity(res.data[0].first_activite);
            })
            .catch(err => {
                console.log(err);
            });
    }

    const getFirstActivity = (idActivity) => {
        axios.get(`${adressApi}/activites/${idActivity}`, {headers: {Authorization: `Bearer ${ManageSession.getToken()}`}})
            .then(res => {
                console.log(res);
                setFirstActivity(res.data.name);
            })
            .catch(err => {
                console.log(err);
            });
    }

    return (
        <div className={"w-full h-svh flex flex-col justify-between items-start"}>
            <div className={"w-full h-svh flex flex-col justify-between items-start"}>
                <div className={"flex flex-col justify-center items-center w-full"}>
                    <img src={logoTitreOlympiades} alt="logo" className={"mb-4 w-10/12"}/>
                </div>
                <div className={"flex flex-col justify-center items-start m-4"}>
                    <h2>Bienvenue,</h2>
                    <h1 className={"text-3xl font-bold ml-4"}>{manageSession.getInfoUser().first_name}</h1>
                </div>
                <div className={"w-full flex flex-col items-center justify-center"}>
                    <div
                        className={"w-10/12 flex flex-col items-center justify-center bg-beigeOlympiades p-4 rounded-lg border-blueOlympiades border-2"}>
                        <p className={"p-4"}>Numero d’équipe : {manageSession.getInfoUser().equipe}</p>
                        <hr className={"w-3/4 border-1 border-blueOlympiades"}/>
                        <p className={"p-4"}>Nom d’équipe : {nomEquipe}</p>
                        <hr className={"w-3/4 border-1 border-blueOlympiades"}/>
                        <p className={"p-4"}>Slogant : {slogantEquipe}</p>
                        <hr className={"w-3/4 border-1 border-blueOlympiades"}/>
                        <div className={"p-4 flex flex-col justify-center items-center"}>
                            <p>Premiere activité : </p>
                            <p>{firstActivity}</p>
                        </div>
                        {
                            manageSession.getInfoUser().role === "1" || manageSession.getInfoUser().role === "7" ? (
                                <div className={"p-2 flex flex-col justify-center items-center"}>
                                    <hr className={"w-3/4 border-1 border-blueOlympiades pt-2"}/>
                                    <p>Chef de l'équipe : pas toi</p>
                                </div>
                            ) : null
                        }

                    </div>
                </div>
                {
                    manageSession.getInfoUser().role === "6" || manageSession.getInfoUser().role === "7" || manageSession.getInfoUser().role === "6" ? (
                        <div className={"w-full flex flex-col items-center justify-center p-4"}>
                            <Link to={"/scanner"}>
                                <button
                                    className={"border border-2 border-blueOlympiades bg-blueOlympiades text-white rounded-lg px-10 py-1 m-1"}>Scanner
                                </button>
                            </Link>
                            <Link to={"/midi"}>
                                <button className={"border border-2 border-gray-500 rounded-lg px-10 py-1 m-1"}>Midi
                                </button>
                            </Link>
                        </div>
                    ) : null
                }
                <div className="w-full flex flex-row justify-between mt-4">
                    <div className="flex flex-row justify-start items-center w-1/2 pl-3">
                        <Link to="/map">
                            <img src={carte} alt="carte" className="w-1/4"/>
                        </Link>
                    </div>
                    <div className="flex flex-row justify-end items-center w-1/2 pr-3">
                        <Link to="/aide" className="flex flex-row w-full justify-between items-center relative">
                            <p>Un problème ?</p>
                            <img src={flecheIncurve} alt="Fleche Incurvé"
                                 className="w-16 z-10 absolute -translate-y-6 translate-x-16"/>
                            <img src={help} alt="demande d'aide" className="w-1/4"/>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default User;