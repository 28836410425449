import React, {useEffect, useState} from 'react';
import axios from "axios";
import config from "../../../conf/config";
import {ManageSession} from "../../../Service/ManageSession";

function GetUsers(props) {

    const [user, setUser] = useState([]);
    const [nomUser, setNomUser] = useState("");
    const [equipes, setEquipes] = useState([]);

    useEffect(() => {
        getEquipes();
    }, []);

    const handleChangeNomUser = (event) => {
        setNomUser(event.target.value);
    }

    const handleClickSearchUser = (event) => {
        event.preventDefault();

        axios.get(`${config.apiUrl}/users/search/${nomUser}`, {headers: {Authorization: `Bearer ${ManageSession.getToken()}`}})
            .then(res => {
                console.log(res);
                setUser(res.data[0]);
                alert('Utilisateur trouvé');
            })
            .catch(err => {
                console.log(err);
                alert('Utilisateur non trouvé')
            });
    }

    const handleChangeUser = (event) => {
        setUser({...user, [event.target.name]: event.target.value});
    };

    const handleSubmitUpdateUser = (event) => {
        event.preventDefault();

        axios.put(`${config.apiUrl}/users/update`, user, {headers: {Authorization: `Bearer ${ManageSession.getToken()}`}})
            .then(res => {
                console.log(res);
                alert('Utilisateur modifié');
            })
            .catch(err => {
                console.log(err);
                alert('Erreur lors de la modification');
            });
    };

    const getEquipes = () => {
        axios.get(`${config.apiUrl}/equipes`, {headers: {Authorization: `Bearer ${ManageSession.getToken()}`}})
            .then(res => {
                console.log(res);
                setEquipes(res.data);
            })
            .catch(err => {
                console.log(err);
            });
    }

    return (
        <div>
            <div>
                <div>
                    <div>
                        <form onSubmit={handleClickSearchUser}>
                            <h1>Rechercher un Utilisateur : </h1>
                            <input type="text" placeholder="Nom de l'utilisateur" onChange={handleChangeNomUser}
                                   className={"border border-2 border-gray-500 p-1"}/>
                            <button className={"border border-2 border-gray-500 rounded p-1 m-1 w-full active:bg-gray-400"}>
                                Rechercher</button>
                        </form>
                    </div>
                    {
                        user.length !== 0 ? (
                            <form onSubmit={handleSubmitUpdateUser}>
                                <table>
                                    <tr>
                                        <th className={"flex justify-start"}>Nom</th>
                                        <td><input type="text" name={"name"} value={user.name}
                                                   onChange={handleChangeUser} className={"bg-gray-200"}/></td>
                                    </tr>
                                    <tr>
                                        <th className={"flex justify-start"}>Prenom</th>
                                        <td><input type="text" name={"second_name"} value={user.second_name}
                                                   onChange={handleChangeUser} className={"bg-gray-200"}/></td>
                                    </tr>
                                    <tr>
                                        <th className={"flex justify-start"}>Mail</th>
                                        <td><input type="text" name={"email"} value={user.email}
                                                   onChange={handleChangeUser} className={"bg-gray-200"}/></td>
                                    </tr>
                                    <tr>
                                        <th className={"flex justify-start"}>Role</th>
                                        <td><input type="text" name={"role"} value={user.role}
                                                   onChange={handleChangeUser} className={"bg-gray-200"}/></td>
                                    </tr>
                                    <tr>
                                        <th className={"flex justify-start"}>Sexe</th>
                                        <td><select name="sexe" onChange={handleChangeUser}>
                                            <option value="1" selected={user.sexe}>Homme</option>
                                            <option value="0" selected={!user.sexe}>Femme</option>
                                        </select></td>
                                    </tr>
                                    <tr>
                                        <th className={"flex justify-start"}>Equipe</th>
                                        <td><select name="equipe" onChange={handleChangeUser}>
                                            {
                                                equipes.map(equipe =>
                                                    <option value={equipe.id} selected={user.equipe === equipe.id}>{equipe.name}</option>
                                                )
                                            }
                                        </select></td>
                                    </tr>
                                </table>
                                <button className={"border border-2 border-gray-500 rounded p-1 m-1 w-full active:bg-gray-400"}>Sauvgarder</button>
                            </form>

                        ) : (
                            <p>Utilisateur non trouvé</p>
                        )
                    }
                </div>
            </div>


        </div>
    );
}

export default GetUsers;