import React, {useEffect, useState} from 'react';
import {QrReader} from "react-qr-reader";
import axios from "axios";
import {ManageSession} from "../Service/ManageSession";
import config from "../conf/config";
import BackButton from "./Component/BackButton";
import CheckRole from "../Service/Securite";
import {useNavigate} from "react-router-dom";

function ScanQrCode(){

    const adressApi = config.apiUrl;

    const navigate = useNavigate();

    const [data, setData] = useState('No result');

    useEffect(() => {
        if (!CheckRole("6")){
            navigate("/redirect");
        }
    }, []);

    const handleChangeValueQr = (event) => {
        setData(event.target.value);
    }

    const handleClickSendQRCode = () => {

        if(data === 'Start_CO'){

        }

        axios.get(`${adressApi}/qrcode/${data}`, {headers: {Authorization: `Bearer ${ManageSession.getToken()}`}})
            .then(res => {
                console.log(res.data[0].id);
                verifyQrCodeEquipe(ManageSession.getInfoUser().equipe, res.data[0].id);
            })
            .catch(err => {
                console.log(err);
            });
    }

    const CheckFinishCO = () => {
        axios.get(`${adressApi}/equipes/finishco/${ManageSession.getInfoUser().equipe}`, {headers: {Authorization: `Bearer ${ManageSession.getToken()}`}})
            .then(res => {
                console.log(res);
                if(res.data.length === 0){
                    StartCO();
                } else {
                    alert("CO déjà terminé");
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    const StartCO = () => {
            axios.post(`${adressApi}/equipes/startco`, {id : ManageSession.getInfoUser().equipe}, {headers: {Authorization: `Bearer ${ManageSession.getToken()}`}})
                .then(res => {
                    console.log(res);
                    alert("Vous avez 1h pour terminer la CO");
                })
                .catch(err => {
                    console.log(err);
                });
    }

    const verifyQrCodeEquipe = (idEquipe, idqrCode) => {
        axios.get(`${adressApi}/qrcodeequipe/${idEquipe}/${idqrCode}`, {headers: {Authorization: `Bearer ${ManageSession.getToken()}`}})
            .then(res => {
                console.log(res);
                if(res.data.length === 0){
                    addQrCodeEquipe(idqrCode);
                } else {
                    alert("QrCode déjà scanné");
                }
            })
            .catch(err => {
                console.log(err);
                alert("Erreur serveur, veuiller réessayer, si le problème persiste contacter l'administrateur");
            });
    }

    const addQrCodeEquipe = (idQrCode) => {
        axios.post(`${adressApi}/qrcodeequipe`, {idEquipe : ManageSession.getInfoUser().equipe, idQrCode},  {headers: {Authorization: `Bearer ${ManageSession.getToken()}`}})
            .then(res => {
                console.log(res);
                addScoreLog();
            })
            .catch(err => {
                console.log(err);
            });
    }

    const addScoreLog = () => {
        axios.post(`${adressApi}/scorelog`, {equipe : ManageSession.getInfoUser().equipe, activite : data, score : 10}, {headers: {Authorization: `Bearer ${ManageSession.getToken()}`}})
            .then(res => {
                console.log(res);
                axios.post(`${adressApi}/equipes/score`, {id : ManageSession.getInfoUser().equipe, score : 10}, {headers: {Authorization: `Bearer ${ManageSession.getToken()}`}})
                    .then(res => {
                        console.log(res);
                        alert("QrCode scanné avec succès")
                    })
                    .catch(err => {
                        console.log(err);
                    });
            })
            .catch(err => {
                console.log(err);
            });
    }

    return (
        <div className={"flex flex-col justify-center items-center h-svh pb-40"}>
            <div className={"flex flex-col m-10 items-center"}>
                <BackButton/>
                <h1 className={"text-3xl font-bold m-4"}>Scanner le qrCode</h1>
                <div className={"w-full m-2"}>
                    <QrReader
                        onResult={(result, error) => {
                            if (!!result) {
                                setData(result?.text);
                            }

                            if (!!error) {
                                console.info(error);
                            }
                        }}
                        style={{ width: '200px' }}
                        constraints={{
                            facingMode: 'environment'
                        }}
                    />
                </div>
                <input type="text" value={data} className={"border-gray-500 border-2 rounded"} onChange={handleChangeValueQr}/>
                <button className={"mt-4 border-gray-500 border-2 rounded p-1 active:border-gray-500"} onClick={handleClickSendQRCode}>Inscrire le QRCode</button>
            </div>

        </div>
    );

}

export default ScanQrCode;
