import {ManageSession} from "./ManageSession";

const CheckRole = (role)=>  {

    if(ManageSession.getInfoUser().role !== role){
         return false;
    } else {
        return true;
    }

}

export default CheckRole;