import React, {useEffect, useState} from 'react';
import logoTitreOlympiades from "../img/logo-titre-olympiades.png";
import axios from "axios";
import config from "../conf/config";
import {Link, useNavigate} from "react-router-dom";
import carte from "../img/ico/carte.png";
import flecheIncurve from "../img/ico/Fleche-incurvé.png";
import help from "../img/ico/levez-la-main-pour-demander.png";
import {ManageSession} from "../Service/ManageSession";
import Cooldown from "./Component/Midi/Cooldown";
import CheckRole from "../Service/Securite";

function Midi(props) {

    const adressApi = config.apiUrl;

    const navigate = useNavigate();

    const [nomEquipe, setNomEquipe] = useState();
    const [slogantEquipe, setSlogantEquipe] = useState();
    const [topThreeScore, setTopThreeScore] = useState([]);
    const [nbEssaie, setNbEssaie] = useState();
    const [position, setPosition] = useState();
    const [essaiePosition, setEssaiePosition] = useState();
    const [nextTeamScore, setNextTeamScore] = useState();
    const [founded, setFounded] = useState();
    const [score, setScore] = useState();
    const openDate = new Date("September 26, 2024 12:00:00").getTime();


    useEffect(() => {

        if (!CheckRole("6")){
            navigate("/redirect");
        }

        getPositionEquipe();
        getInfoEquipe();
        getTopThreeScore();

    }, []);

    const getPositionEquipe = () => {
        axios.get(`${adressApi}/equipes/position/${ManageSession.getInfoUser().equipe}`, {headers: {Authorization: `Bearer ${ManageSession.getToken()}`}})
            .then(res => {
                console.log(res);
                setPosition(res.data[0].position);
            })
            .catch(err => {
                console.log(err);
            });
    }

    const getNextTeamScore = (score) => {
        axios.get(`${adressApi}/equipes/nextteamescore/${score}`, {headers: {Authorization: `Bearer ${ManageSession.getToken()}`}})
            .then(res => {
                console.log(res);
                setNextTeamScore(res.data[0].score);
            })
            .catch(err => {
                console.log(err);
            });
    }

    const getInfoEquipe = () => {
        axios.get(`${adressApi}/equipes/${ManageSession.getInfoUser().equipe}`, {headers: {Authorization: `Bearer ${ManageSession.getToken()}`}})
            .then(res => {
                console.log(res);
                console.log("reussi : "+ res.data[0].successful);
                setNbEssaie(res.data[0].essaie);
                setScore(res.data[0].score);
                getNextTeamScore(res.data[0].score);
                setFounded(res.data[0].successful);
            })
            .catch(err => {
                console.log(err);
            });
    }

    const getTopThreeScore = () => {
        axios.get(`${adressApi}/equipes/topscore`, {headers: {Authorization: `Bearer ${ManageSession.getToken()}`}})
            .then(res => {
                console.log(res);
                setTopThreeScore(res.data);
            })
            .catch(err => {
                console.log(err);
            });
    }

    const handleCheckNomEquipe = (e) => {
        setNomEquipe(e.target.value)
        axios.get(`${adressApi}/equipes/name/${e.target.value}`, {headers: {Authorization: `Bearer ${ManageSession.getToken()}`}})
            .then(res => {
                console.log(res);
                if(res.data.length === 0){
                    console.log("Nom d'équipe disponible");
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    const handleSaveNameEquipe = (e) => {
        e.preventDefault();
        axios.post(`${adressApi}/equipes/name`, {name: nomEquipe, id: ManageSession.getInfoUser().equipe}, {headers: {Authorization: `Bearer ${ManageSession.getToken()}`}})
            .then(res => {
                console.log(res);
                alert("Félicitaion, votre nom d'équipe est enregistré !")
            })
            .catch(err => {
                console.log(err);
            });
    }

    const handleChangeSlogantEquipe = (e) => {
        setSlogantEquipe(e.target.value)
    }

    const handleSaveSlogantEquipe = (e) => {
        e.preventDefault();
        axios.post(`${adressApi}/equipes/slogant`, {slogant: slogantEquipe, id: ManageSession.getInfoUser().equipe}, {headers: {Authorization: `Bearer ${ManageSession.getToken()}`}})
            .then(res => {
                console.log(res);
                alert("Félicitaion, votre slogant est enregistré !")
            })
            .catch(err => {
                console.log(err);
            });
    }

    const handleChangeEssaiePosition = (e) => {
        setEssaiePosition(e.target.value);
    }

    const handleSubmitEssaiePosition = (e) => {
        e.preventDefault();

        setNbEssaie(nbEssaie - 1);

        console.log(essaiePosition + " " + position);

        if(parseInt(essaiePosition) === position){
            alert("Bravo vous avez trouvé votre position !");
            setNbEssaie(0);
            setFounded(true);
            addBonus();
            saveSuccesfull();
        }
        else{
            saveEssaie();
            if(parseInt(essaiePosition) < position){
                alert("Plus bas !");
            }else {
                alert("Plus haut !");
            }
        }
    }

    const addBonus = () => {
        axios.post(`${adressApi}/equipes/score`, {id: ManageSession.getInfoUser().equipe, score: 10}, {headers: {Authorization: `Bearer ${ManageSession.getToken()}`}})
            .then(res => {
                console.log(res);
                alert("Bravo vous avez gagné 10 points !");
            })
            .catch(err => {
                console.log(err);
            });
    }

    const saveEssaie = () => {
        axios.put(`${adressApi}/equipes/essaie`, {id: ManageSession.getInfoUser().equipe, essaie: nbEssaie}, {headers: {Authorization: `Bearer ${ManageSession.getToken()}`}})
            .then(res => {
                console.log(res);
            })
            .catch(err => {
                console.log(err);
            });
    }

    const saveSuccesfull = () => {
        axios.put(`${adressApi}/equipes/succesfull`, {id: ManageSession.getInfoUser().equipe, succesfull: 1}, {headers: {Authorization: `Bearer ${ManageSession.getToken()}`}})
            .then(res => {
                console.log(res);
            })
            .catch(err => {
                console.log(err);
            });
    }

    return (
        <div className={"w-full h-svh flex flex-col justify-between items-start"}>
            <div className={"w-full h-svh flex flex-col justify-between items-start"}>
                <div className={"flex flex-col justify-center items-center w-full"}>
                    <img src={logoTitreOlympiades} alt="logo" className={"mb-4 w-10/12"}/>
                </div>


                {
                    openDate >= Date.now() ? (
                        <Cooldown/>
                    ) : (
                        <div className={"w-full flex flex-col justify-center items-center"}>
                            <div className={"w-full flex flex-col justify-center items-center m-1"}>
                                <div
                                    className={"w-10/12 flex flex-col items-center justify-center bg-beigeOlympiades p-4 rounded-lg border-blueOlympiades border-2"}>
                                    <h2>Créer le nom de votre équipe :</h2>
                                    <form className={"w-full flex flex-row justify-center items-center"}
                                          onSubmit={handleSaveNameEquipe}>
                                        <input type="text" onChange={handleCheckNomEquipe}
                                               className={"rounded m-4 border-2 border-blueOlympiades"}/>
                                        <button type={"submit"}
                                                className={"border-2 border-blueOlympiades bg-blueOlympiades text-white p-1 rounded"}>Valider
                                        </button>
                                    </form>
                                    <h2>Créer le slogant de votre équipe :</h2>
                                    <form className={"w-full flex flex-row justify-center items-center"}
                                          onSubmit={handleSaveSlogantEquipe}>
                                        <input type="text" onChange={handleChangeSlogantEquipe}
                                               className={"rounded m-4 border-2 border-blueOlympiades"}/>
                                        <button type={"submit"}
                                                className={"border-2 border-blueOlympiades bg-blueOlympiades text-white p-1 rounded"}>Valider
                                        </button>
                                    </form>
                                </div>

                                <div className={"w-full flex flex-col justify-center items-center m-1"}>
                                    <div
                                        className={"w-10/12 flex flex-col items-center justify-center bg-beigeOlympiades p-4 rounded-lg border-blueOlympiades border-2"}>
                                        <h2>Top 3 des équipes :</h2>
                                        <ul>
                                            {topThreeScore.map((equipe, index) => {
                                                return (
                                                    <li key={index}>Top {index + 1} : {equipe.score}</li>
                                                )
                                            })}
                                        </ul>
                                        <div className={"flex flex-col justify-center items-center"}>
                                            <h2>Devinez votre position : {nbEssaie} essaie</h2>
                                            <form className={"w-full flex flex-row justify-center items-center"}
                                                  onSubmit={handleSubmitEssaiePosition}>
                                                <input
                                                    disabled={founded || nbEssaie === 0}
                                                    placeholder={founded === true ? "Vous avez trouvé" : nbEssaie === 0 ? "Plus d'essaie" : "Votre position"}
                                                    value={nbEssaie === 0 ? "" : essaiePosition}
                                                    type="text"
                                                    className={"rounded m-4 border-2 border-blueOlympiades"}
                                                    onChange={handleChangeEssaiePosition}
                                                />
                                                <button
                                                    disabled={founded || nbEssaie === 0}
                                                    type={"submit"}
                                                    className={"border-2 border-blueOlympiades bg-blueOlympiades text-white p-1 rounded"}>Valider
                                                </button>
                                            </form>
                                            {
                                                founded === true || nbEssaie === 0 ?
                                                    <p className={"text-sm"}>Vous êtes a {nextTeamScore - score}pts de
                                                        l'équipe
                                                        suivante</p> : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }


                <div className="w-full flex flex-row justify-between">
                    <div className="flex flex-row justify-start items-center w-1/2 pl-3">
                        <Link to="/map">
                            <img src={carte} alt="carte" className="w-1/4"/>
                        </Link>
                    </div>
                    <div className="flex flex-row justify-end items-center w-1/2 pr-3">
                        <Link to="/aide" className="flex flex-row w-full justify-between items-center relative">
                            <p>Un problème ?</p>
                            <img src={flecheIncurve} alt="Fleche Incurvé"
                                 className="w-16 z-10 absolute -translate-y-6 translate-x-16"/>
                            <img src={help} alt="demande d'aide" className="w-1/4"/>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Midi;