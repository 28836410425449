import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import "./index.css";
import App from "./App";
import Inscription from "./Pages/Inscription";
import Connexion from "./Pages/Connexion";
import Redirect from "./Pages/Redirect";
import Benevole from "./Pages/Benevole";
import Aide from "./Pages/Aide";
import Admin from "./Pages/admin";
import User from "./Pages/User";
import ScanQrCode from "./Pages/ScanQrCode";
import Gestion from "./Pages/Gestion";
import GetUsers from "./Pages/Component/Gestion/GetUsers";
import Verification from "./Pages/Verification";
import MdpOublier from "./Pages/MdpOublier";
import SendNewMdp from "./Pages/SendNewMdp";
import Map from "./Pages/map";
import Midi from "./Pages/Midi";
import Ffsu from "./Pages/Ffsu";
import CreateEquipe from "./Pages/Component/Gestion/CreateEquipe";
import GenerateToken from "./Pages/Component/Gestion/GenerateToken";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
    },
    {
        path: "/inscription",
        element: <Inscription/>,
    },
    {
        path: "/connexion",
        element: <Connexion/>,
    },
    {
        path: "/redirect",
        element: <Redirect/>,
    },
    {
        path: "/benevole",
        element: <Benevole/>,
    },
    {
        path: "/aide",
        element: <Aide/>,
    },
    {
        path: "/admin",
        element: <Admin/>,
    },
    {
        path: "/user",
        element: <User/>,
    },
    {
        path: "/scanner",
        element: <ScanQrCode/>,
    },
    {
        path: "/gestion",
        element: <Gestion/>,
        children : [
            {
                path: "/gestion/utilisateur",
                element: <GetUsers/>,
            },
            {
                path: "/gestion/createequipe",
                element: <CreateEquipe/>,
            },
            {
                path: "/gestion/token",
                element: <GenerateToken/>,
            }
        ]
    },
    {
        path: "/verification/:id",
        element: <Verification/>,
    },
    {
        path: "/mdpoublie",
        element: <MdpOublier/>,
    },
    {
        path: "/setnewmdp",
        element: <SendNewMdp/>,
    },
    {
        path: "/midi",
        element: <Midi/>,
    },
    {
        path: "/map",
        element: <Map/>,
    },
    {
        path: "/ffsu",
        element: <Ffsu/>,
    },
    {
        path: "*",
        element: <h1>404</h1>,
    }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);