import React, {useEffect, useState} from 'react';
import axios from "axios";
import config from "../../../conf/config";
import {ManageSession} from "../../../Service/ManageSession";

function GenerateToken(props) {

    const apiUrl = config.apiUrl;

    const [token, setToken] = useState("");

    useEffect(() => {
        generateToken();
    }, []);

    const generateToken = () => {
        axios.post(`${apiUrl}/token/generate`, {name: "moi"}, {headers: {Authorization: `Bearer ${ManageSession.getToken()}`}})
            .then((response) => {
                console.log(response);
                setToken(response.data.token);
            });
    };

    return (
        <div className={"w-full flex flex-col justify-center items-center"}>
            <div>
                Token généré : {token}
            </div>
        </div>
    );
}

export default GenerateToken;