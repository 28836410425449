import React, {useState} from 'react';
import axios from "axios";
import config from "../../../conf/config";

function VerifCode({ onSuccess }) {

    const urlApi = config.apiUrl;

    const [email, setEmail] = useState("");
    const [code, setCode] = useState("");

    const handleChangeMail = (e) => {
        setEmail(e.target.value);
    }

    const handleChangeCode = (e) => {
        setCode(e.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        axios.get(`${urlApi}/resetcodemdp/get/${email}/${code}`)
            .then(res => {
                console.log(res);
                axios.delete(`${urlApi}/resetcodemdp/delete/${email}`)
                    .then(res => {
                        console.log(res);
                        alert('ligne suprimmer');
                        onSuccess(email);
                    })
                    .catch(err => {
                        console.log(err);
                    });
            })
            .catch(err => {
                console.log(err);
                alert('Code incorrect');
            });
    }

    return (
        <div className={"w-full flex flex-col justify-center items-center"}>
            <div className={"flex flex-col justify-center items-center w-10/12 rounded-lg bg-gray-200"}>
                <h1 className={"text-xl font-bold m-4"}>Verification code</h1>
                <form className={"flex flex-col w-full justify-around items-center"} onSubmit={handleSubmit}>
                    <div className={"w-3/4 flex flex-col justify-around m-2"}>
                        <label htmlFor="code">Email : </label>
                        <input type="email" name="code" id="code"
                               className={"border border-2 border-gray-400 rounded"} onChange={handleChangeMail}/>
                    </div>
                    <div className={"w-3/4 flex flex-col justify-around m-2"}>
                        <label htmlFor="code">Code : </label>
                        <input type="text" name="code" id="code"
                               className={"border border-2 border-gray-400 rounded"} onChange={handleChangeCode}/>
                    </div>
                    <button type="submit" className={"border border-2 border-gray-400 rounded m-2 p-1 active:bg-gray-400"}>Envoyer</button>
                </form>
            </div>
        </div>
    );
}

export default VerifCode;