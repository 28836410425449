import React, {useEffect, useState} from 'react';
import axios from "axios";
import config from "../conf/config";
import CheckRole from "../Service/Securite";
import {useNavigate} from "react-router-dom";
import {ManageSession} from "../Service/ManageSession";

function Ffsu() {

    const ApiUrl = config.apiUrl;

    const navigate = useNavigate();

    const [users, setUsers] = useState([]);

    useEffect(() => {
        if (!CheckRole("4")){
            navigate("/redirect");
        }
        GetUsers();
    }, []);

    const GetUsers = () => {
        axios.get(`${ApiUrl}/users/roles`, {headers: {Authorization: `Bearer ${ManageSession.getToken()}`}})
            .then((response) => {
                setUsers(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }



    const GetBirthdayDay = (birthday) => {
        let date = new Date(birthday);
        let day = date.getDate();

        return day;
    }

    const GetBirthdayMonth = (birthday) => {
        let date = new Date(birthday);
        let month = date.getMonth();

        return month;
    }

    const GetBirthdayYear = (birthday) => {
        let date = new Date(birthday);
        let year = date.getFullYear();

        return year;
    }

    const SetAge = (birthday) => {
        let date = new Date();

        let year = date.getFullYear();
        let age = year - GetBirthdayYear(birthday);

        console.log(GetBirthdayDay(birthday));

        if(GetBirthdayMonth(birthday)+1 > date.getMonth()){
            if (GetBirthdayDay(birthday) > date.getDate()) {
                age -= 1;
            }
        }

        return age;
    }

    const FormateBirthday = (birthday) => {
        let date = new Date(birthday);

        let day = date.getDate();
        let month = date.getMonth();
        let year = date.getFullYear();

        if(day < 10){
            day = "0" + day;
        }
        if(month < 10){
            month = "0" + month;
        }

        return year + "-" + month + "-" + day;
    }

    const SetDepartement = (dep) => {
        switch (dep) {
            case "info":
                return "Informatique";
            case "GC":
                return "Génie Civil";
            case "GEA":
                return "Gestion des Entreprises et des Administrations";
            case "GB":
                return "Génie Biologique";
            case "TC":
                return "Techniques de Commercialisation";
            case "GMP":
                return "Génie Mécanique et Productique";
        }
    }

    const UpdateLicence = (event) => {
        axios.put(`${ApiUrl}/users/licence/${event.target.id}`, {headers: {Authorization: `Bearer ${ManageSession.getToken()}`}})
            .then((response) => {
                console.log(response);
                GetUsers();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <div>
            <div className={"w-full flex flex-col justify-center items-center"}>
                <h1 className={"text-3xl"}>FFSU</h1>
            </div>
            <div className={"w-full flex flex-col justify-center items-start"}>
                <table className={"w-full"}>
                    <thead>
                    <tr>
                        <th className={"text-left"}>Nom</th>
                        <th className={"text-left"}>Prénom</th>
                        <th className={"text-left"}>Email</th>
                        <th className={"text-left"}>Anniversaire</th>
                        <th className={"text-left"}>Age</th>
                        <th className={"text-left"}>Departement</th>
                        <th className={"text-left"}>Licence Faite</th>
                    </tr>
                    </thead>
                    <tbody>
                    {users.map(user => {
                        if(user.role === 7 || user.role === 4){
                            return null;
                        }else{
                            return (
                                <tr key={user.id}>
                                    <td>{user.second_name}</td>
                                    <td>{user.name}</td>
                                    <td>{user.email}</td>
                                    <td>{FormateBirthday(user.birthday)}</td>
                                    <td>{SetAge(user.birthday)}</td>
                                    <td>{SetDepartement(user.departement)}</td>
                                    <td><input type="checkbox" id={user.id} onClick={UpdateLicence}
                                               checked={user.licenced}/></td>
                                </tr>
                            );
                        }
                    })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Ffsu;