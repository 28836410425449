import React, {useEffect, useState} from 'react';
import config from "../conf/config";
import axios from "axios";
import logoTitreOlympiades from "../img/logo-titre-olympiades.png";
import {ManageSession} from "../Service/ManageSession";

function Aide() {

    const adressApi = config.apiUrl;

    const [activites, setActivites] = useState([]);
    const [equipes, setEquipes] = useState([]);
    const [allNvUrgence, setAllNvUrgence] = useState([]);

    const [activite, setActivite] = useState();
    const [equipe, setEquipe] = useState();
    const [urgence, setUrgence] = useState();

    const [description, setDescription] = useState("");

    useEffect(() => {
        getActivites();
        getEquipes();
        getAllNvUrgence();
    }, []);

    const getActivites = () => {
        axios.get(`${adressApi}/activites`)
            .then(res => {
                console.log(res);
                setActivites(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const getEquipes = () => {
        axios.get(`${adressApi}/equipes`, {headers: {Authorization: `Bearer ${ManageSession.getToken()}`}})
            .then(res => {
                console.log(res);
                setEquipes(res.data);
            })
            .catch(err => {
                console.log(err);
            });
    }

    const getAllNvUrgence = () => {
        axios.get(`${adressApi}/urgences`)
            .then(res => {
                console.log(res);
                setAllNvUrgence(res.data);
            })
            .catch(err => {
                console.log(err);
            });
    }

    const handleChangeActivite = (event) => {
        setActivite(event.target.value);
    }

    const handleChangeEquipe = (event) => {
        setEquipe(event.target.value);
    }

    const handleChangeUrgence = (event) => {
        setUrgence(event.target.value);
    }

    const handleChangeDescription = (event) => {
        setDescription(event.target.value);
    }

    const handleClickSend = () => {

        if (activite === null || equipe === null || urgence === null || description === "") {
            alert("Veuillez remplir tous les champs");
        } else {
            axios.post(`${adressApi}/aide`, {
                activite: activite,
                equipe: equipe,
                urgence: urgence,
                text: description
            })
                .then(res => {
                    console.log(res);
                    alert("Votre demande a bien été envoyée");
                })
                .catch(err => {
                    console.log(err);
                    alert("Une erreur est survenue");
                });
        }
    }

    return (
        <div className={"w-full flex flex-col justify-center items-center"}>
            <div className={"w-full flex flex-col justify-center items-center"}>
                <div className={"flex flex-col justify-center items-center w-full"}>
                    <img src={logoTitreOlympiades} alt="logo" className={"my-4 w-10/12"}/>
                </div>
                <h1 className="text-3xl my-4">Demande D'aide</h1>
                <div
                    className={"w-10/12 flex flex-col items-center justify-center bg-beigeOlympiades p-4 rounded-lg border-blueOlympiades border-2"}>
                    <div className={"flex flex-col justify-center"}>
                        <div>
                            <div className={"flex flex-row w-full"}>
                                <div className={"relative m-1 w-1/2"}>
                                    <label className="sr-only">Underline select</label>
                                    <select onChange={handleChangeActivite} required
                                            className="block py-2.5 pl-4 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-black appearance-none focus:outline-none focus:ring-0 focus:border-black peer">
                                        <option>Activité</option>
                                        {activites.map(activite => (
                                            <option key={activite.id} value={activite.id}>{activite.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className={"relative m-1 w-1/2"}>
                                    <label className="sr-only">Underline select</label>
                                    <select onChange={handleChangeEquipe} required
                                            className={"block py-2.5 pl-4 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-black appearance-none focus:outline-none focus:ring-0 focus:border-black peer"}>
                                        <option>Equipe</option>
                                        {equipes.map(equipe => {
                                            return <option value={equipe.id}>{equipe.name}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className={"relative m-1"}>
                            <label className="sr-only">Underline select</label>
                            <select onChange={handleChangeUrgence} required
                                    className={"block py-2.5 pl-4 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-black appearance-none focus:outline-none focus:ring-0 focus:border-black peer"}>
                                <option>Type Urgence</option>
                                {allNvUrgence.map(urgence => {
                                    return <option value={urgence.id}>{urgence.name}</option>
                                })}
                            </select>
                        </div>
                    </div>
                    <div className={"mt-4 flex flex-col justify-center"}>
                        <label htmlFor="" htmlFor={"description"}>Description du problème</label>
                        <textarea name="" id="description" cols="30" rows="10"
                                  className={"border border-gray-500 rounded mt-2"}
                                  onChange={handleChangeDescription}></textarea>
                    </div>
                </div>
                <button className={"border border-gray-500 rounded my-2 px-10"} onClick={handleClickSend}>Envoyer</button>
            </div>
        </div>
    );
}

export default Aide;