import React, {useEffect, useState} from "react";
import axios from "axios";
import config from "../conf/config";
import {Link, useNavigate} from "react-router-dom";
import BottomNav from "./Component/BottomNav";
import logoTitreOlympiades from "../img/logo-titre-olympiades.png";
import carte from "../img/ico/carte.png";
import flecheIncurve from "../img/ico/Fleche-incurvé.png";
import help from "../img/ico/levez-la-main-pour-demander.png";
import CheckRole from "../Service/Securite";
import {ManageSession} from "../Service/ManageSession";

export default function Benevole(){

    const adressApi = config.apiUrl;

    const navigate  = useNavigate();

    const [activites, setActivites] = useState([]);
    const [equipes, setEquipes] = useState([]);
    const [maxPoints, setMaxPoints] = useState(0);
    const [score, setScore] = useState(null);
    const [membres, setMembres] = useState(null);
    const [malus, setMalus] = useState(0);
    const [selectedActivite, setSelectedActivite] = useState("");
    const [selectedActiviteID, setSelectedActiviteID] = useState();
    const [selectedEquipeID, setSelectedEquipeID] = useState();

    useEffect(() => {
        if(!CheckRole("2")){
            navigate("/redirect");
        }
        GetActivites();
        GetEquipes();
    }, []);

    const GetActivites = () => {
        axios.get(`${adressApi}/activites`)
            .then(res => {
                setActivites(res.data);
            })
    }

    const GetEquipes = () => {
        axios.get(`${adressApi}/equipes`, {headers: {Authorization: `Bearer ${ManageSession.getToken()}`}})
            .then(res => {
                setEquipes(res.data);
            })
    }

    const handleChangeActivity = (event) => {
        activites.forEach(activite => {
            if(activite.id === parseInt(event.target.value)){
                setMaxPoints(activite.maxPoints);
                setSelectedActivite(activite.name);
                setSelectedActiviteID(activite.id);
                setScore("");
            }
        })
    }

    const handleScoreChange = (event) => {
        if(event.target.value > 100){
            setScore(0);
        }else{
            if(event.target.value < 0){
                setScore(0);
            } else {
                setScore(event.target.value);
            }
        }
    }

    const handleChangeEquipe = (event) => {

        setSelectedEquipeID(event.target.value);
        setScore("");

        axios.get(`${adressApi}/users/equipe/${event.target.value}`, {headers: {Authorization: `Bearer ${ManageSession.getToken()}`}})
            .then(res => {
                setMembres(res.data);
            })
    }

    const handleClickMalus = (event) => {
        if(event.target.checked){
            setMalus(malus+1);
        } else {
            setMalus(malus-1);
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if(score !== ""){
            const activite = selectedActiviteID;
            const equipe = selectedEquipeID;
            const points = score - (2*(membres.length - malus));

            axios.get(`${adressApi}/scorelog/${activite}/${equipe}`, {headers: {Authorization: `Bearer ${ManageSession.getToken()}`}})
                .then(res => {
                    console.log(res);
                    if(res.data.length === 0){
                        axios.post(`${adressApi}/scorelog`, {score : points, activite, equipe})
                            .then(res => {
                                console.log(res);
                                axios.post(`${adressApi}/equipes/score`, {id : equipe, score : points})
                                    .then(res => {
                                        console.log(res);
                                        alert(`vous gagner ${(score - (2*(membres.length - malus)))} points dans en ${selectedActivite} vous avez ${(membres.length - malus)} membres`);
                                    })
                                    .catch(err => {
                                        console.log(err);
                                    })
                            })
                            .catch(err => {
                                console.log(err);
                            })
                    } else {
                        alert("cette equipe a deja renseigner ces point pour cette activité, contacter les administrateur en cas d'erreur");
                    }
                })
        } else {
            alert("il faut inscrir le nombre de points");
        }
    }

    return (
        <div className="App flex items-center justify-center h-svh overflow-hidden">
            <div className={"w-full flex justify-between flex-col items-center h-full"}>
                <img src={logoTitreOlympiades} alt="logo" className={"mb-4 w-10/12"}/>
                <div className={"w-10/12 flex flex-col justify-between items-center"}>
                    <div className={"w-full flex justify-around"}>
                        <div className={"relative w-1/2 m-1"}>
                            <label className="sr-only">Underline select</label>
                            <select name="activite" onChange={handleChangeActivity} required
                                    className={"block py-2.5 pl-4 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer"}>
                                <option>Activite</option>
                                {
                                    activites.map(activite =>
                                        <option value={activite.id}>{activite.name}</option>
                                    )
                                }
                            </select>
                        </div>
                        <div className={"relative w-1/2 m-1"}>
                            <label className="sr-only">Underline select</label>
                            <select name="Equipe" onChange={handleChangeEquipe} required
                                    className={"block py-2.5 pl-4 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer"}>
                                <option>Equipe</option>
                                {
                                    equipes.map(equipe =>
                                        <option value={equipe.id}>{equipe.name}</option>
                                    )
                                }
                            </select>
                        </div>
                    </div>
                    <div className="relative w-full">
                        <input
                            type="number"
                            id="score"
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 text-center bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            name="score"
                            onChange={handleScoreChange}
                            placeholder=" " // Add a placeholder with a single space
                            value={score === null ? "" : score}
                            required
                        />
                        <label
                            htmlFor="score"
                            className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-4 left-2.5 z-10 origin-[0] bg-white px-2
                peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4"
                        >
                            Nombre de points :
                        </label>
                    </div>
                </div>
                {membres ? (
                    <div className={"w-full flex flex-col justify-center items-center"}>
                        <h1>Nombre de membres : {membres.length}</h1>
                        <div
                            className={"flex flex-col items-center justify-center border-2 rounded-lg border-blueOlympiades w-10/12 bg-beigeOlympiades m-4"}>
                            {
                                membres.map(membre =>
                                    <div
                                        className={`flex flex-row justify-between items-center w-11/12 border-2 border-blueOlympiades border-solid rounded-lg m-3 bg-white`}>
                                        <div className={"flex flex-row w-auto justify-center items-center"}>
                                            <p className={"m-1"}>{membre.name}</p>
                                            <p>{membre.second_name}</p>
                                        </div>
                                        <input id="react-checkbox" type="checkbox" value=""
                                               className="w-4 h-4 border-solid rounded m-1" onClick={handleClickMalus}/>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                ) : (
                    <h1>Selectionez une équipe </h1>
                )}
                <div className={"flex flex-col justify-around items-center"}>
                    <button
                        className={"border-2 border-blueOlympiades rounded-lg p-1 bg-blueOlympiades active:bg-gray-500 hover:border-gray-500 text-white px-10 m-2"}
                        onClick={handleSubmit}>Envoyer les points
                    </button>
                </div>
                <div className="w-full flex flex-row justify-between">
                    <div className="flex flex-row justify-start items-center w-1/2 pl-3">
                        <Link to="/map">
                            <img src={carte} alt="carte" className="w-1/4"/>
                        </Link>
                    </div>
                    <div className="flex flex-row justify-end items-center w-1/2 pr-3">
                        <Link to="/aide" className="flex flex-row w-full justify-between items-center relative">
                            <p>Un problème ?</p>
                            <img src={flecheIncurve} alt="Fleche Incurvé"
                                 className="w-16 z-10 absolute -translate-y-6 translate-x-16"/>
                            <img src={help} alt="demande d'aide" className="w-1/4"/>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}