import React, {useState} from 'react';
import ProgressBar from "./Component/NewMDP/ProgressBar";
import VerifCode from "./Component/NewMDP/VerifCode";
import SetNewMDP from "./Component/NewMDP/SetNewMDP";
import {flushSync} from "react-dom";
import GoToConnect from "./Component/NewMDP/GoToConnect";

function SendNewMdp() {

    const [step, setStep] = useState(1);
    const [email, setEmail] = useState("");

    const handleNextStep = (email) => {
        if(step === 1){
            setEmail(email);
        }

        setStep(step + 1);
    }

    return (
        <div className={"w-full flex flex-col items-center"}>

            <h1 className={"text-3xl font-bold m-4"}>Reset Mot de passe</h1>

            <div className={"mt-32 w-full"}>
                <div className={"m-4"}>
                    <ProgressBar step={step} totalSteps={3}/>
                </div>
                {step === 1 && (<VerifCode onSuccess={handleNextStep}/>)}
                {step === 2 && (<SetNewMDP onSuccess={handleNextStep} email={email}/>)}
                {step === 3 && (<GoToConnect/>)}
            </div>

        </div>
    );
}

export default SendNewMdp;