import React, {useEffect, useState} from 'react';
import axios from "axios";
import config from "../conf/config";

function MdpOublier() {

    const urlApi = config.apiUrl;

    const [mail, setMail] = useState("");
    const [code, setCode] = useState("");

    useEffect(() => {
        generateRandomMDP();
    }, []);

    const handleChangeMail = (e) => {
        setMail(e.target.value);
    }

    const generateRandomMDP = () => {
        let mdp = "";
        let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (let i = 0; i < 8; i++) {
            mdp += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        setCode(mdp);
    }

    const saveRandMdp = (event) => {
        event.preventDefault();

        axios.post(`${urlApi}/resetcodemdp/save`, {code :code , email : mail})
            .then(res => {
                console.log(res);
                sendMail();
            })
            .catch(err => {
                console.log(err);
            });
    }

    const sendMail = () => {
        axios.post(`${urlApi}/mailer/send`, {destinataire : mail, sujet : "Code création nouveau mot de passe", contenu : `Votre code est : ${code} utilisez ce code sur cette page https://olympiades.iut-amiens.fr/setnewmdp`})
            .then(res => {
                console.log(res);
                alert('Mail envoyé');
            })
            .catch(err => {
                console.log(err);
            });
    }

    return (
        <div className={"w-full h-svh flex flex-col justify-center items-center"}>
            <h1 className={"text-3xl font-bold m-4"}>Mot de passe oublier</h1>
            <form onSubmit={saveRandMdp} className={"flex flex-col w-full justify-around items-center"}>
                <div className={"w-3/4 flex flex-row justify-around"}>
                    <label htmlFor="email">Email : </label>
                    <input type="email" name="email" id="email" onChange={handleChangeMail}
                           className={"border border-2 border-gray-400 rounded"}/>
                </div>
                <button type="submit" className={"border border-2 border-gray-400 rounded m-2 p-1"}>Envoyer</button>
            </form>
        </div>
    );
}

export default MdpOublier;