import React from 'react';

function ProgressBar({ step, totalSteps }) {

    const progress = (step / totalSteps) * 100;

    return (
        <div style={{width: '100%', backgroundColor: '#e0e0df', borderRadius: '5px'}}>
            <div
                style={{
                    width: `${progress}%`,
                    height: '10px',
                    backgroundColor: '#76c7c0',
                    borderRadius: '5px',
                    transition: 'width 0.3s ease-in-out',
                }}
            />
        </div>

    );
}

export default ProgressBar;