import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import config from "../conf/config";

function Verification() {

    const adressApi = config.apiUrl;

    const {id} = useParams();
    const [user, setUser] = useState({});

    useEffect(() => {
        getUserById();
    }, []);

    const getUserById = () => {
        axios.get(`${adressApi}/users/me/${id}`, {headers: {'Authorization': `Bearer ${process.env.TOKEN_FULL_ACCESS}`}})
            .then((response) => {
                setUser(response.data[0]);

                axios.get(`${adressApi}/users/checked/${id}`, {headers: {'Authorization': `Bearer ${process.env.TOKEN_FULL_ACCESS}`}})
                    .then((response) => {
                        console.log(response);
                    })
                    .catch((error) => {
                        alert("Erreur lors de la verification du compte contacte un administrateur a l'adresse suivante: \n" +
                            "olympiade.bde.iut.amiens@gmail.com");
                        console.error(error);
                    });

            })
            .catch((error) => {
                console.error(error);
            });
    }

    return (
        <div>
            <h1>Félicitations {user.name} {user.second_name}, tu as désormais vérifié ton compte, tu peux maintenant te connecter</h1>
            <Link to={'/connexion'} className={"border-2 border-blueOlympiades bg-blueOlympiades text-white rounded"}><button>Connectes toi</button></Link>
        </div>
    );
}

export default Verification;