import React, {useEffect, useState} from 'react';
import axios from "axios";
import CryptoJS from "crypto-js";
import config from "../../../conf/config";

function SetNewMDP({onSuccess, email}) {

    const apiUrl = config.apiUrl;

    const [mdp, setMdp] = useState("");
    const [mdpConfirm, setMdpConfirm] = useState("");

    useEffect(() => {
        alert(email);
    }, []);

    const handleChangeMDP = (event) => {
        setMdp(event.target.value);
    }

    const handleChangeMDPConfirm = (event) => {
        setMdpConfirm(event.target.value);
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if (mdp === mdpConfirm) {
            axios.post(`${apiUrl}/users/resetmdp`, {
                newMdp: CryptoJS.SHA256(mdp).toString(),
                email: email
            }).then(res => {
                console.log(res);
                onSuccess();
            }).catch(err => {
                alert("Erreur lors de la modification du mot de passe" + err);
                console.log(err);
            });

        } else {
            alert("Les mots de passe ne correspondent pas");
        }
    }

    return (
        <div className={"w-full flex flex-col justify-center items-center"}>
            <div className={"flex flex-col justify-center items-center w-10/12 rounded-lg bg-gray-200"}>
                <h1 className={"text-xl font-bold m-4"}>Nouveau mot de passe</h1>
                <form className={"flex flex-col w-full justify-around items-center"} onSubmit={handleSubmit}>
                    <div className={"w-3/4 flex flex-col justify-around m-2"}>
                        <label htmlFor="mdp">Mot de passe : </label>
                        <input type="password" name="code" id="mdp"
                               className={"border border-2 border-gray-400 rounded"} onChange={handleChangeMDP}/>
                    </div>
                    <div className={"w-3/4 flex flex-col justify-around m-2"}>
                        <label htmlFor="mdpconfirm">Confirme le mot de passe : </label>
                        <input type="password" name="code" id="mdpconfirm"
                               className={"border border-2 border-gray-400 rounded"} onChange={handleChangeMDPConfirm} style={{borderColor : mdp !== mdpConfirm ? "red" : null}}/>
                    </div>
                    <button type="submit"
                            className={"border border-2 border-gray-400 rounded m-2 p-1 active:bg-gray-400"}>Envoyer
                    </button>
                </form>
            </div>
        </div>
    );
}

export default SetNewMDP;