import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Importer l'image pour l'icône de marqueur personnalisée
import markerIcon from '../../img/ico/maker.png';

// Définir l'icône personnalisée pour les marqueurs
const customMarkerIcon = L.icon({
    iconUrl: markerIcon,
    iconSize: [41, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
});

function MapComponent() {
    const center = [49.871144, 2.2641492];
    const [markers, setMarkers] = useState([]);

    useEffect(() => {
        // Générer des coordonnées pour 5 marqueurs autour du centre de la carte
        const newMarkers = Array.from({ length: 5 }, (_, idx) => ({
            lat: center[0] + Math.random() * 0.01 - 0.0005,
            lng: center[1] + Math.random() * 0.01 - 0.0005,
            activity: `Activité A${idx + 1}` // Ajouter le nom de l'activité
        }));
        setMarkers(newMarkers);
    }, []);

    return (
        <MapContainer center={center} zoom={15} style={{ height: '75vh', width: '90%', borderRadius: '10px'}}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {markers.map((marker, idx) => (
                <Marker key={idx} position={[marker.lat, marker.lng]} icon={customMarkerIcon}>
                    <Popup>
                        {marker.activity}
                    </Popup>
                </Marker>
            ))}
        </MapContainer>
    );
}

export default MapComponent;
