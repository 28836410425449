import {useEffect} from "react";
import {ManageSession} from "../Service/ManageSession";
import {useNavigate} from "react-router-dom";

export default function Redirect() {

    const navigate = useNavigate();

    useEffect(() => {
        SelectPage();
    }, []);

    const SelectPage = () => {
        switch (ManageSession.getInfoUser().role) {
            case "1":
                navigate('/user');
                break;

            case "2" :
                navigate('/benevole');
                break;

            case "3" :
                navigate('/admin');
                break;

            case "4" :
                navigate('/ffsu');
                break;

            case "5" :
                navigate('/admin');
                break;

            case "6" :
                navigate('/user');
                break;

            case "7" :
                navigate('/gestion');
                break;

            case "":
                navigate('/');
                break;
        }
    }

    return (
        <div>Loading...</div>
    )
}