let setInfoUser = (first_name, second_name, id, role, equipe, checked) => {
    sessionStorage.setItem("first_name", first_name);
    sessionStorage.setItem("second_name", second_name);
    sessionStorage.setItem("id", id);
    sessionStorage.setItem("role", role);
    sessionStorage.setItem("equipe", equipe);
    sessionStorage.setItem("checked", checked);
}

let isConnect = () => {
    if (sessionStorage.getItem("id") !== null){
        return true;
    } else {
        return false;
    }

}

let logout = () => {
    sessionStorage.clear();
}

let getInfoUser = () => {
    const first_name = sessionStorage.getItem("first_name");
    const second_name = sessionStorage.getItem("second_name");
    const id = sessionStorage.getItem("id");
    const role   = sessionStorage.getItem("role");
    const equipe = sessionStorage.getItem("equipe");
    const checked = sessionStorage.getItem("checked");


    const infoUser = {first_name, second_name, id, role, equipe, checked};

    return infoUser;
}

let setToken = (token) => {
    sessionStorage.setItem("token", token);
}

let getToken = () => {
    return sessionStorage.getItem("token");
}

export const ManageSession = {
    setInfoUser, isConnect, logout, getInfoUser, setToken, getToken
}