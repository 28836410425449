import React from 'react';
import {Link} from "react-router-dom";

function GoToConnect(props) {
    return (
        <div className={"w-full flex flex-col justify-center items-center"}>
            <div className={"flex flex-col justify-center items-center w-10/12 rounded-lg bg-gray-200"}>
                <h1 className={"text-xl font-bold m-4"}>Felicitaion</h1>
                <p>Votre mot de passe a bien été modifié</p>
                <Link to={"/connexion"}>
                    <button
                        className={"border border-2 border-gray-400 rounded m-2 p-1 active:bg-gray-400"}>Se connecter
                    </button>
                </Link>


            </div>
        </div>
    );
}

export default GoToConnect;