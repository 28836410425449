// import './App.css';
import Logo_olympiade from './img/Logo_olympiade.png';

function App() {
  return (
      <div className="App flex items-center justify-center h-svh overflow-hidden">
          <div className={"flex justify-center flex-col items-center"}>
              <div className={"-rotate-12 flex flex-col justify-start items-start titre-acceuil mb-4"}>
                  <h2 className="text-3xl">Journée</h2>
                  <h1 className="text-7xl">Cohésion</h1>
              </div>
              <div className={"flex flex-col m-8"}>
                  <a href={"/inscription"}>
                  <button
                          className={"rounded-2xl m-2 p-2 border-solid border-2 border-blueOlympiades w-40 bg-blueOlympiades active:bg-gray-400 text-white"}>Inscription
                      </button>
                  </a>
                  <a href={"/connexion"}>
                      <button
                          className={"rounded-2xl m-2 p-2 border-solid border-2 w-40 bg-gray-200 active:bg-gray-400"}>Connexion
                      </button>
                  </a>
              </div>
              <div className={"flex flex-col items-center justify-center w-8/12 md:w-2/12"}>
                    <img src={Logo_olympiade} alt="logo-olympiades" className={"w-full"}/>
              </div>
          </div>
      </div>
  );
}

export default App;
