import React from 'react';

function BackButton() {

    const handleClickGoBack = () => {
        window.history.back();
    }

    return (
        <div>
            <button onClick={handleClickGoBack}>
                back
            </button>
        </div>
    );
}

export default BackButton;