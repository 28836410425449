import React, {useEffect, useState} from 'react';
import axios from "axios";
import config from "../../../conf/config";
import {ManageSession} from "../../../Service/ManageSession";
import CheckRole from "../../../Service/Securite";

function CreateEquipe(props) {

    const ApiUrl = config.apiUrl;

    const EquipeSize = 12;

    const [users, setUsers] = useState([]);
    const [numEquipe, setNumEquipe] = useState(1);
    const [infoUser, setInfoUser] = useState([]);
    const [gbUser, setGbUser] = useState([]);
    const [gmpUser, setGmpUser] = useState([]);
    const [geaUser, setGeaUser] = useState([]);
    const [gcUser, setGcUser] = useState([]);
    const [tcUser, setTcUser] = useState([]);

    useEffect(() => {
        CheckRole(7);
        getUsers();
        parseUserByDepartement();
    }, []);

    const getUsers = () => {
        axios.get(`${ApiUrl}/users/role/1`, {headers: {Authorization: `Bearer ${ManageSession.getToken()}`}})
            .then((response) => {
                setUsers(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const parseUserByDepartement = () => {
        users.map((user) => {
            switch (user.departement) {
                case "GB":
                    setGbUser([...gbUser, user]);
                    break;
                case "GMP":
                    setGmpUser([...gmpUser, user]);
                    break;
                case "GEA":
                    setGeaUser([...geaUser, user]);
                    break;
                case "GC":
                    setGcUser([...gcUser, user]);
                    break;
                case "TC":
                    setTcUser([...tcUser, user]);
                    break;
                case "info":
                    setInfoUser([...infoUser, user]);
                    break;
            }
        });
    }

    const CheckIfUserIsInEquipe = (id) => {
        let isInEquipe = false;
        users.map((user) => {
            if(user.id === id){
                isInEquipe = true;
            }
        });
        return isInEquipe;
    }

    const CreateEquipeInDB = (equipe) => {
        const nameEquipe = "Equipe"+equipe

        axios.post(`${ApiUrl}/equipes/create`, {name: nameEquipe}, {headers: {Authorization: `Bearer ${ManageSession.getToken()}`}})
            .catch((error) => {
                alert("Erreur lors de la création de l'équipe : "+ error);
            });
    }

    const CreateEquipeAleatoire = () => {

    }

    return (
        <div>
            <button className={"rounded bg-blueOlympiades border-blueOlympiades border-2"}>Création des équipes</button>
        </div>
    );
}

export default CreateEquipe;