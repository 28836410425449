import React from 'react';
import carte from "../../img/ico/carte.png";
import {Link} from "react-router-dom";
import flecheIncurve from "../../img/ico/Fleche-incurvé.png";
import help from "../../img/ico/levez-la-main-pour-demander.png";

function BottomNav() {
    return (
        <div className="w-full flex flex-row justify-between">
            <div className="flex flex-row justify-start items-center w-1/2 pl-3">
                <Link to="/map">
                    <img src={carte} alt="carte" className="w-1/4"/>
                </Link>
            </div>
            <div className="flex flex-row justify-end items-center w-1/2 pr-3">
                <Link to="/aide" className="flex flex-row w-full justify-between items-center relative">
                    <p>Un problème ?</p>
                    <img src={flecheIncurve} alt="Fleche Incurvé"
                         className="w-16 z-10 absolute -translate-y-6 translate-x-16"/>
                    <img src={help} alt="demande d'aide" className="w-1/4"/>
                </Link>
            </div>
        </div>
    );
}

export default BottomNav;