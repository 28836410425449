import React from 'react';
import MapComponent from "./Component/MapComponent";

function Map(props) {
    return (
        <div className={"flex flex-col items-center justify-center"}>
            <h1 className="text-3xl my-4">Map</h1>
            <MapComponent/>
        </div>
    );
}

export default Map;