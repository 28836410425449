import React, { useEffect, useState } from 'react';

function Countdown() {
    // Définir la date cible
    const targetDate = new Date("September 26, 2024 12:00:00").getTime();

    // Fonction pour calculer le temps restant
    const calculateTimeLeft = () => {
        const now = new Date().getTime();
        const difference = targetDate - now;

        // Calculer les jours, heures, minutes et secondes restants
        return {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
            minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
            seconds: Math.floor((difference % (1000 * 60)) / 1000),
        };
    };

    // État pour stocker le temps restant
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    // Utiliser useEffect pour mettre à jour le temps restant chaque seconde
    useEffect(() => {
        const interval = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        // Nettoyer l'intervalle lorsqu'il n'est plus nécessaire
        return () => clearInterval(interval);
    }, []);

    // Afficher le compte à rebours
    return (
        <div className={"w-full h-svh flex flex-col justify-between items-center"}>
            <div className={"w-full text-sm flex flex-col justify-center items-center"}>
                <h2 className={"text-lg text-center"}>Les mini activité du midi seron bientot <b>ouverte</b></h2>
                {timeLeft.days} jours {timeLeft.hours} heures {timeLeft.minutes} minutes{" "}
                {timeLeft.seconds} secondes
            </div>
        </div>
    );
}

export default Countdown;
