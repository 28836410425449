import {useEffect, useState} from "react";
import axios from "axios";
import CryptoJS from 'crypto-js';
import config from "../conf/config";
import invisible from "../img/ico/invisible.png"
import visible from "../img/ico/visible.png"
import logoTitreOlympiades from '../img/logo-titre-olympiades.png'
import {useNavigate} from "react-router-dom";

export default function Inscription(){

    const navigate = useNavigate();

    const adressApi = config.apiUrl;

    const [inputs, setInputs] = useState({});
    const [activitesPref, setActivitesPref] = useState({});
    const [roleid, setRole] = useState();
    const [activites, setActivites] = useState([]);
    const [isValid, setIsValid] = useState(true);
    const [isDateValid, setIsDateValid] = useState(true);
    const [pswVisible, setPswVisible] = useState(false);
    const [presLength, setPresLength] = useState(0);


    useEffect(() => {
        GetActivites();
    }, []);

    const isEmailValid = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@etud\.u-picardie\.fr$/;
        return emailRegex.test(email);
    };

    const isValidDate = (dateString) => {
        const regex = /^\d{4}-\d{2}-\d{2}$/;
        return regex.test(dateString);
    };

    const VerifUser = (email) => {
        axios.get(`${adressApi}/users/search/${email}`, {headers: {'Authorization': `Bearer ${process.env.TOKEN_FULL_ACCESS}`}})
            .then(res => {
                if (isValid && isDateValid) {
                    if(res.data.length !== 0){
                        alert("Email deja existant");
                    } else {
                        PostUser(inputs);
                    }
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    const PostUser = (info) => {
        axios.post(`${adressApi}/users/save`, info, {headers: {'Authorization': `Bearer ${process.env.TOKEN_FULL_ACCESS}`}})
            .then(res => {
                alert("Fellicitation tu est inscrit, Maintenant active ton compte avec le mail que tu as reçu");
                SendMail(res.data.userId);
                navigate("/");
            })
            .catch(err => {
                alert("Erreur lors de l'inscription"+ err);
            });
    }

    const SendMail = (id) => {
        axios.post(`${adressApi}/mailer/send`, {destinataire : inputs.email, sujet : "Activation de compte", contenu : `Bonjour ${inputs.name} ${inputs.second_name} Pour activer votre compte cliquez sur le lien suivant : https://olympiades.iut-amiens.fr/verification/${id}`})
            .then(res => {
                alert("Mail envoyé");
            })
            .catch(err => {
                alert("Erreur lors de l'envoie du mail veuillez contacter un administrateur : teo.lambert@etud.u-picardie.fr");
            });
    }

    const PostActivitePref = () => {
        axios.post(`${adressApi}/activites/pref/save`, activitesPref)
            .then(res => {
                let activitepref = res.data.activiteId;
                setInputs(values => ({...values, activitepref: activitepref}));

                const {name, second_name, sexe, role, birthday, password, departement, email} = inputs;

                const infoUser = {name, second_name, sexe, role, birthday, password, departement, email, activitepref};

                VerifUser(inputs.email);

            })
            .catch(err => {
                console.log(err);
            });
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        console.log(inputs);

        if (roleid === "2") {
            PostActivitePref();
        } else {
            console.log("inputs before axios.post:", inputs);

            VerifUser(inputs.email);
        }
    }

    const GetActivites = () => {
        axios.get(`${adressApi}/activites`)
            .then(res => {
                setActivites(res.data);
            })
    }

    const handleActivieChange = (event) => {
        setActivitesPref(values => ({...values, [event.target.name]: event.target.value}));
    }

    const handleChange = (event) => {

        if(event.target.name === "password"){
            setInputs(values => ({...values, [event.target.name]: CryptoJS.SHA256(event.target.value).toString()}));
        } else {
            if(event.target.name === "email") {
                if (event.target.value.substr(-1, 1) === "@") {
                    event.target.value += "etud.u-picardie.fr";
                }
                setIsValid(isEmailValid(event.target.value));
            }
            if(event.target.name === "birthday"){
                if(event.target.value.length === 4 || event.target.value.length === 7){
                    if (presLength < event.target.value.length) {
                        event.target.value += "-";
                    }
                }else{
                    if (event.target.value.length > 10) {
                        event.target.value = inputs.birthday;
                    }
                }
                setIsDateValid(isValidDate(event.target.value));
                setPresLength(event.target.value.length);
            }
            setInputs(values => ({...values, [event.target.name]: event.target.value}));
            if(event.target.name === "role"){
                setRole(event.target.value);
            }
        }
    }

    const handleClickVisible = () => {
        setPswVisible(!pswVisible);
    }


    return (
        <div className="App flex items-center justify-center h-svh">
            <div className={"m-4 flex flex-col items-center justify-center"}>
                <img src={logoTitreOlympiades} alt="logo" className={"mb-4 w-10/12 md:w-2/12"}/>
                <div className={"flex flex-col h-4/6"}>
                    <form className={"flex flex-col justify-between items-center h-full"} onSubmit={handleSubmit}>
                        <table>
                            <tr>
                                <td className="relative w-full">
                                    <div className="relative">
                                        <input
                                            type="text"
                                            id="name"
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                            name="name"
                                            onChange={handleChange}
                                            placeholder=" " // Add a placeholder with a single space
                                            required
                                        />
                                        <label
                                            htmlFor="name"
                                            className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-4 left-2.5 z-10 origin-[0] bg-white px-2
                peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4"
                                        >
                                            Prenom :
                                        </label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="relative w-full">
                                    <div className="relative">
                                        <input
                                            type="text"
                                            id="second_name"
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                            name="second_name"
                                            onChange={handleChange}
                                            placeholder=" " // Add a placeholder with a single space
                                            required
                                        />
                                        <label
                                            htmlFor="second_name"
                                            className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 left-2.5 z-10 origin-[0] bg-white px-2
                peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4"
                                        >
                                            Nom :
                                        </label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="relative w-full">
                                    <div className="relative">
                                        <input
                                            type="text"
                                            id="birthday"
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-200 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                            name="birthday"
                                            onChange={handleChange}
                                            placeholder="      AAAA-MM-JJ" // Add a placeholder with a single space
                                            style={{borderColor: isDateValid ? '' : 'red'}}
                                            value={inputs.birthday}
                                            required
                                        />
                                        <label
                                            htmlFor="birthday"
                                            className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 left-2.5 z-10 origin-[0] bg-white px-2
                peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4"
                                        >
                                            Naissance :
                                        </label>

                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className={"relative w-full"}>
                                    <div className={"relative"}>
                                        <input
                                            type="email"
                                            id="email"
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                            name="email"
                                            onChange={handleChange}
                                            placeholder="    prénom.nom@etud.u-picardie.fr" // Add a placeholder with a single space
                                            style={{borderColor: isValid ? '' : 'red'}}
                                            required
                                        />
                                        <label
                                            htmlFor="email"
                                            className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 left-2.5 z-10 origin-[0] bg-white  px-2
                peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4"
                                        >
                                            Email Etudiant:
                                        </label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="relative w-full">
                                    <div className="relative">
                                        <input
                                            type={pswVisible ? "text" : "password"}
                                            id="password"
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                            name="password"
                                            onChange={handleChange}
                                            placeholder=" " // Add a placeholder with a single space
                                            required
                                        />
                                        <label
                                            htmlFor="password"
                                            className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 left-2.5 z-10 origin-[0] bg-white px-2
            peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4"
                                        >
                                            Mot de passe :
                                        </label>
                                        <div className="absolute inset-y-0 right-0 flex items-center pr-3 w-8" onClick={handleClickVisible}>
                                            {
                                                pswVisible ? (
                                                    <div>
                                                        <img src={visible} alt=""/>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <img src={invisible} alt=""/>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className={"relative w-full"}>
                                    <div className={"relative"}>
                                        <label className="sr-only">Underline select</label>
                                        <select name="sexe" onChange={handleChange} required
                                                className="block py-2.5 pl-4 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer">
                                            <option>Civilité</option>
                                            <option value={1}>Homme</option>
                                            <option value={0}>Femme</option>
                                        </select>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className={"relative w-full"}>
                                    <div className={"relative"}>
                                        <label className="sr-only">Underline select</label>
                                        <select name="role" onChange={handleChange} required
                                                className={"block py-2.5 pl-4 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer"}>
                                            <option>Année d'étude</option>
                                            <option value={1}>BUT 1</option>
                                            <option value={2}>BUT 2</option>
                                            <option value={2}>BUT 3</option>
                                        </select>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className={"relative w-full"}>
                                    <div className={"relative"}>
                                        <label className="sr-only">Underline select</label>
                                        <select name="departement" onChange={handleChange} required
                                                className={"block py-2.5 pl-4 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer"}>
                                            <option>Département</option>
                                            <option value={"info"}>Informatique</option>
                                            <option value={"GB"}>Génie Biologique</option>
                                            <option value={"GC"}>Génie Civil & Construction Durable</option>
                                            <option value={"GMP"}>Génie Mécanique et Productique</option>
                                            <option value={"GEA"}>Gestion des Entreprises et Administrations</option>
                                            <option value={"TC"}>Techniques de Commercialisation</option>
                                        </select>
                                    </div>
                                </td>
                            </tr>
                        </table>
                        {roleid === "2" && (
                            <div className={"mt-4"}>
                                <h1 className={"text-lg font-bold"}>Choix des activitées par préférence</h1>
                                <table className={"w-full"}>
                                    <tr>
                                        <td className={"relative w-1/2"}>
                                            <div className={"relative"}>
                                                <label className="sr-only">Underline select</label>
                                                <select name="activite_1" onChange={handleActivieChange} required
                                                        className={"block py-2.5 pl-4 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer"}>
                                                    <option>Activité n°1</option>
                                                    {
                                                        activites.map(activite => (
                                                            <option value={activite.id}>{activite.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </td>
                                        <td className={"relative w-1/2"}>
                                            <div className={"relative"}>
                                                <label className="sr-only">Underline select</label>
                                                <select name="activite_2" onChange={handleActivieChange} required
                                                        className={"block py-2.5 pl-4 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer"}>
                                                    <option>Activité n°2</option>
                                                    {
                                                        activites.map(activite => (
                                                            <option value={activite.id}>{activite.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={"relative w-1/2"}>
                                            <div className={"relative"}>
                                                <label className="sr-only">Underline select</label>
                                                <select name="activite_3" onChange={handleActivieChange} required
                                                        className={"block py-2.5 pl-4 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer"}>
                                                    <option>Activité n°3</option>
                                                    {
                                                        activites.map(activite => (
                                                            <option value={activite.id}>{activite.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </td>
                                        <td className={"relative w-1/2"}>
                                            <div className={"relative"}>
                                                <label className="sr-only">Underline select</label>
                                                <select name="activite_4" onChange={handleActivieChange} required
                                                        className={"block py-2.5 pl-4 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer"}>
                                                    <option>Activité n°4</option>
                                                    {
                                                        activites.map(activite => (
                                                            <option value={activite.id}>{activite.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                                <div className="relative">
                                    <input
                                        type="num"
                                        id="num"
                                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                        name="num"
                                        onChange={handleChange}
                                        placeholder=" " // Add a placeholder with a single space
                                        required
                                    />
                                    <label
                                        htmlFor="num"
                                        className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 left-2.5 z-10 origin-[0] bg-white px-2
                peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4"
                                    >
                                        Numéro de téléphone :
                                    </label>
                                </div>
                            </div>
                        )}
                        <button
                            className={"rounded-2xl m-2 p-2 border-solid border-2 border-blueOlympiades w-40 bg-blueOlympiades active:bg-gray-400 text-white"}>Inscription
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}