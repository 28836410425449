import React, {useEffect} from 'react';
import {Link, Outlet, useNavigate} from "react-router-dom";
import CheckRole from "../Service/Securite";

function Gestion() {

    const navigate = useNavigate();

    useEffect(() => {
        if (!CheckRole("7")){
            navigate("/redirect");
        }
    }, []);

    return (
        <div className={"w-full h-svh flex flex-col justify-center items-center"}>
            <div className={"w-full flex flex-col justify-center items-center"}>
                <h1 className="text-3xl font-bold m-4">Gestion</h1>
                <div className={"flex flex-row justify-around items-center"}>
                    <Link to={"/gestion/utilisateur"}>
                        <button className={"border border-2 border-gray-500 rounded m-1 w-20"} >Utilisateur</button>
                    </Link>
                    <button className={"border border-2 border-gray-500 rounded m-1 w-20"} >Equipe</button>
                    <button className={"border border-2 border-gray-500 rounded m-1 w-20"} >Activité</button>
                    <button className={"border border-2 border-gray-500 rounded m-1 w-20"} >QrCode</button>
                    <Link to={"/gestion/createequipe"}>
                        <button className={"border border-2 border-gray-500 rounded m-1 w-20"}>Creation d'équipes</button>
                    </Link><Link to={"/gestion/token"}>
                        <button className={"border border-2 border-gray-500 rounded m-1 w-20"}>Creation d'équipes</button>
                    </Link>
                </div>
                <div>
                    <Outlet/>
                </div>
            </div>
        </div>
    );
}

export default Gestion;