import React, {useEffect, useState} from 'react';
import config from "../conf/config";
import axios from "axios";
import CheckRole from "../Service/Securite";
import {ManageSession} from "../Service/ManageSession";
import {useNavigate} from "react-router-dom";

function Admin() {

    const adressApi = config.apiUrl;

    const navigate = useNavigate();

    const [demandeAide, setDemandeAide] = useState([]);
    const [activites, setActivites] = useState([]);
    const [equipes, setEquipes] = useState([]);
    const [allNvUrgence, setAllNvUrgence] = useState([]);


    useEffect(() => {
        if(!CheckRole("5")){
            navigate("/redirect");
        }
        getDemandeAide();
        getActivites();
        getEquipes();
        getAllNvUrgence();
    }, []);

    const getDemandeAide = () => {
        axios.get(`${adressApi}/aide`)
            .then(res => {
                console.log(res);
                setDemandeAide(res.data);
            })
            .catch(err => {
                console.log(err);
            });
    }

    const getActivites = () => {
        axios.get(`${adressApi}/activites`)
            .then(res => {
                console.log(res);
                setActivites(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const getEquipes = () => {
        axios.get(`${adressApi}/equipes`, {headers: {Authorization: `Bearer ${ManageSession.getToken()}`}})
            .then(res => {
                console.log(res);
                setEquipes(res.data);
            })
            .catch(err => {
                console.log(err);
            });
    }

    const getAllNvUrgence = () => {
        axios.get(`${adressApi}/urgences`)
            .then(res => {
                console.log(res);
                setAllNvUrgence(res.data);
            })
            .catch(err => {
                console.log(err);
            });
    }

    const normalizeDateHour = (date) => {
        let newDate = new Date(date);
        return newDate.toLocaleDateString() + " " + newDate.toLocaleTimeString();
    }

    return (
        <div className={"flex flex-col justify-center items-center"}>
            <h1 className={"text-3xl font-bold m-4"}>Admin</h1>
            {
                demandeAide.map((aide, index) => {
                    return (
                        <div key={index} className={"w-11/12 flex flex-col justify-center items-center border-2 rounded p-2"}>
                            <div className={"flex flex-row w-full justify-between"}>
                                {
                                    activites.map(activite => {
                                        if(activite.id === aide.activite){
                                            return <p className={"border border-2 rounded p-1"}>{activite.name}</p>
                                        }
                                    })
                                }
                                {
                                    equipes.map(equipe => {
                                        if(equipe.id === aide.equipe){
                                            return <p className={"border border-2 rounded p-1"}>{equipe.name}</p>
                                        }
                                    })
                                }
                                {
                                    allNvUrgence.map(urgence => {
                                        if(urgence.id === aide.urgence){
                                            return <p className={"border border-2 rounded p-1"}>{urgence.name}</p>
                                        }
                                    })
                                }
                            </div>
                            <p className={"border border-2 rounded p-1 m-1 w-full"}>{aide.text}</p>
                            <div className={"flex flex-row justify-between items-center w-full"}>
                                <p className={"flex-1"}>{normalizeDateHour(aide.date_creation)}</p>
                                <div className={"flex flex-row w-full justify-end flex-1"}>
                                    <p className={"m-1"}>Résolut</p>
                                    <input type="checkbox"/>
                                </div>
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
}

export default Admin;