import {useEffect, useState} from "react";
import axios from "axios";
import CryptoJS from "crypto-js";
import {Link, useNavigate} from "react-router-dom";
import { ManageSession } from "../Service/ManageSession";
import config from "../conf/config";
import logoTitreOlympiades from "../img/logo-titre-olympiades.png";

export default function Connexion(){

    const adressApi = config.apiUrl;

    const navigate = useNavigate();

    const [isValid, setIsValid] = useState(true);

    const [inputs, setInputs] = useState({});

    useEffect(() => {
        ManageSession.logout();
    },[]);

    const isEmailValid = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@etud\.u-picardie\.fr$/;
        return emailRegex.test(email);
    };

    const handleChange = (event) => {
        if(event.target.name === "email"){
            if (event.target.value.substr(-1, 1) === "@") {
                event.target.value += "etud.u-picardie.fr";
            }
            setIsValid(isEmailValid(event.target.value));
            if (isEmailValid(event.target.value)) {
                setInputs(values => ({...values, [event.target.name]: event.target.value}))
            }
        } else {
            setInputs(values => ({...values, [event.target.name]: event.target.value}))
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        const cryptPassword = CryptoJS.SHA256(inputs.password).toString()

        const params = {password : cryptPassword, email: inputs.email};

        if (!isValid) {
            alert("Veuillez renseigner une adresse mail valide");
            return;
        } else {
            axios.post(`${adressApi}/users/connexion`, params, {headers: {'Authorization': `Bearer ${process.env.TOKEN_FULL_ACCESS}`}})
                .then(res => {
                    console.log(res);
                    if(res.data.statu === "connecté"){
                        ManageSession.setInfoUser(res.data.users[0].name, res.data.users[0].second_name, res.data.users[0].id, res.data.users[0].role, res.data.users[0].equipe, res.data.users[0].checked);
                        ManageSession.setToken(res.data.token);
                        if(res.data.users[0].checked !== 0){
                            navigate("/redirect");
                        } else {
                            alert("Votre compte n'est pas encore validé, veuillez verifier vos mails");
                            navigate("/");
                        }

                    } else {
                        if(res.data.statu === "utilisteur inconue"){
                            alert("utilisateur inconue")
                        } else {
                            alert("mot de passe incorecte");
                        }
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        }
    }

    return (
        <div className="App flex items-center justify-center h-svh overflow-hidden">
            <div className={"flex justify-around flex-col items-center h-3/6"}>
                <img src={logoTitreOlympiades} alt="logo" className={"mb-4 w-10/12 md:w-2/12"}/>
                <div className={"flex flex-col w-full"}>
                    <form className={"flex flex-col justify-between items-center h-full"} onSubmit={handleSubmit}>
                        <table className={"w-10/12 m-4 md:w-4/12"}>
                            <tr className={"w-full"}>
                                <td className="relative w-full">
                                    <div className="relative">
                                        <input
                                            type="text"
                                            id="email"
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                            name="email"
                                            onChange={handleChange}
                                            placeholder=" " // Add a placeholder with a single space
                                            required
                                            style={{borderColor: isValid ? "" : "red"}}
                                        />
                                        <label
                                            htmlFor="email"
                                            className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 left-2.5 z-10 origin-[0] bg-white px-2
                peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4"
                                        >
                                            Mail Etudiant :
                                        </label>
                                    </div>
                                </td>
                            </tr>
                            <tr className={"w-full"}>
                                <td className="relative w-full">
                                    <div className="relative">
                                        <input
                                            type="password"
                                            id="password"
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                            name="password"
                                            onChange={handleChange}
                                            placeholder=" " // Add a placeholder with a single space
                                            required
                                        />
                                        <label
                                            htmlFor="password"
                                            className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-4 left-2.5 z-10 origin-[0] bg-white px-2
                peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4"
                                        >
                                            Mot de passe :
                                        </label>
                                    </div>
                                </td>
                            </tr>
                        </table>
                        <button
                            className={"rounded-2xl m-4 py-2 px-4 border-solid border-2 w-40 bg-blueOlympiades border-blueOlympiades active:bg-gray-400 text-white"}>Connexion
                        </button>
                        <Link to={"/mdpoublie"} className={"text-xs border border-2 rounded-full p-1"}>Mot de passe
                            oublié ?</Link>
                    </form>
                </div>
            </div>
        </div>
    )
}